import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Link } from "gatsby"
//import Img from 'gatsby-image';
//import Button from './button';

const Dcd2020 = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`gatsby`, `application`, `react`, `portfolio`]}
    />
    <>
      <nav>
        <Link to="/blog/membres" style={{ display: `inline-block`, marginRight: `1rem`}} >Src: AX</Link> {" "}
        <Link to="/blog/carmel" style={{ display: `inline-block`, marginRight: `1rem`}} >Bureau</Link> {" "}
        <Link to="/blog/fideles" style={{ display: `inline-block`, marginRight: `1rem`}} >Src: X.org</Link> {" "}
        <Link to="/blog/dcd2020" style={{ display: `inline-block`, marginRight: `1rem`}} >Liste lue</Link> {" "}
      </nav>
    </>
    <h4>Liste des X décédés lue en 2020</h4>
    <div className="member-list">
      {data.allDcd2020Json.edges.map(member => (
        <div key={member.node.id} className="member-list__item">
          
          <div className="member-list__content">
            <h4> {member.node.Prenom} {member.node.Nom} ({member.node.Promotion})</h4>
            <div className="member-list__excerpt">
              {}
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
);

export default Dcd2020;
// ...your graphql query

export const dcd2020Query = graphql`
  query {
    allDcd2020Json(sort: { order: ASC, fields: [Promotion, Nom] }) {
    
      edges {
        node {
          Prenom
          Nom
          Promotion
          DateDC
        }
      }
    }
  }


`;